import { CheckCircleOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Spin, Table, TableColumnsType } from 'antd'
import { SetStateAction, Suspense, useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { PageHeader } from '../../components/display/PageHeader'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useMeSites } from '../../hooks/useMeSites'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'

type DataType = {
  key: React.Key
  name: string
  age: number
  address: string
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Site number',
    dataIndex: 'siteNumber',
  },
  {
    title: 'Site name',
    dataIndex: 'title',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Timezone',
    dataIndex: 'timeZone',
  },
]

const DOWNLOAD_TYPES = {
  ASSIGNMENTS: 'ASSIGNMENTS',
  AUDITS: 'AUDITS',
}

export const DMView = ({ projectData }) => {
  const { projectId } = useParams() as { projectId: string }

  const { data: sites } = useMeSites(projectId)

  const [siteData, setSiteData] = useState([])
  const [selectedSites, setSelectedSites] = useState<React.Key[]>([])
  const [fileDownloading, setFileDownloading] = useState<boolean>(false)
  const [showError, setShowError] = useState(false)
  const [downloadType, setDownloadType] = useState<string>()

  const [file, setFile] = useState<any>()

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const { animated, spring } = useFadeIn()

  const showDownloadModal = () => {
    setIsDownloadModalOpen(true)
  }

  useEffect(() => {
    setSiteData(buildSiteTable(sites))
  }, [sites])

  //
  // Antd table needs a key to be unique and since we do not have a key coming from the backend
  // we set the key to be the _id
  //
  const buildSiteTable = (sites) => {
    return sites.map((site) => {
      return {
        ...site,
        key: site._id,
      }
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setShowError(false)
      setSelectedSites(selectedRowKeys)
    },
  }

  const exportSelected = () => {
    if (selectedSites.length > 0) {
      setDownloadType(DOWNLOAD_TYPES.ASSIGNMENTS)

      showDownloadModal()

      setFileDownloading(true)

      setShowError(false)

      setFile(undefined)

      const p = ENDPOINTS.SUBJECTS.exportSelectedAssignments(projectId, selectedSites as string[])

      return getReq(p)
        .then((result) => {
          setFile(result)
          setFileDownloading(false)
        })
        .catch((error) => {
          alert(error.message)
          setFileDownloading(false)
        })
    } else {
      setShowError(true)
    }
  }

  const exportAudits = () => {
    setDownloadType(DOWNLOAD_TYPES.AUDITS)

    showDownloadModal()

    setFileDownloading(true)

    setShowError(false)

    setFile(undefined)

    const p = ENDPOINTS.SUBJECTS.exportSubjectAudits(projectId)

    return getReq(p)
      .then((result) => {
        setFile(result)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <>
      <animated.div style={spring} className="  d-flex flex-column flex-fill">
        <div
          className="container-fluid bg-white"
          style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="container px-lg-6 mb-3" style={{}}>
            <PageHeader
              title={`Data management`}
              body="Effortlessly download and manage subject data across sites to ensure data integrity and validity for
            insightful analysis."
            ></PageHeader>
          </div>
        </div>

        <div className="container mt-2 px-4 mb-7">
          <div className="row mt-3">
            <div className="col-12">
              <div
                className="h-100 px-4 py-4 rounded-1"
                style={{
                  boxShadow: '0px 3px 4px -3px rgba(0, 0, 0, .4)',
                  background: 'white',
                }}
              >
                <div className="col-12 row">
                  <div className="col-8">
                    <h5 className="" style={{ color: 'black' }}>
                      Subjects Audit Export
                    </h5>
                    <p className="p-small" style={{ color: 'black' }}>
                      Enhance your Subject Assignment Exports by downloading the associated audit activity log for all
                      subjects.
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-end">
                      <Link to="download">
                        <Button className="my-2" type="primary" loading={fileDownloading} onClick={exportAudits}>
                          Request subjects audit logs
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3 mt-3">
            <div className="col-12">
              <div
                className="h-100 px-4 py-4 rounded-1"
                style={{
                  boxShadow: '0px 3px 4px -3px rgba(0, 0, 0, .4)',
                  background: 'white',
                }}
              >
                <div className="col-12 row">
                  <div className="col-8">
                    <h5 className="" style={{ color: 'black' }}>
                      Subject Assignment Export
                    </h5>
                    <p className="p-small" style={{ color: 'black' }}>
                      Choose the sites to export subject assignments and data from.
                    </p>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <Table
                    bordered
                    size="large"
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={siteData}
                    pagination={false}
                  />
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <Link to="download">
                      {/* <Button className="my-3" type="primary" onClick={showDownloadModal}> */}
                      <Button className="my-2" type="primary" onClick={exportSelected} loading={fileDownloading}>
                        Request assignments
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      {selectedSites.length === 0 && showError ? (
                        <div className="mt-3 p-small text-danger mb-2 d-flex justify-content-start">
                          Data cannot be exported without site selection
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Routes>
            <Route
              path="/download"
              element={
                <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                  <DownloadModal
                    setFileDownloading={setFileDownloading}
                    isModalOpen={isDownloadModalOpen}
                    setModalOpen={setIsDownloadModalOpen}
                    file={file}
                    setFile={setFile}
                    type={downloadType}
                    studyName={projectData.title}
                  ></DownloadModal>
                </Suspense>
              }
            />
          </Routes>
        </div>
      </animated.div>
    </>
  )
}

type DownloadExportTypes = {
  setFileDownloading: (e: SetStateAction<boolean>) => void
  isModalOpen: boolean
  setModalOpen: (e: SetStateAction<boolean>) => void
  file: any
  setFile: any
  type: string | undefined
  studyName: string
}

const assignmentBodyText =
  'Exporting assignment data may take a few seconds to a few minutes, depending on the dataset size.'

const auditBodyText = 'Exporting audit data may take a few seconds to a few minutes, depending on the dataset size.'

const DownloadModal = ({
  isModalOpen,
  setModalOpen,
  file,
  setFile,
  type,
  studyName,
  setFileDownloading,
}: DownloadExportTypes) => {
  const navigate = useNavigate()

  const popupTitle = 'Your Export is In-Progress'
  const popupBody = type === DOWNLOAD_TYPES.ASSIGNMENTS ? assignmentBodyText : auditBodyText

  const [downloadStarted, setDownloadStarted] = useState<boolean>(false)

  const handleExportOk = () => {
    setFile(undefined)

    setModalOpen(false)

    setDownloadStarted(false)
  }

  const handleExportCancel = () => {
    setFile(undefined)

    setModalOpen(false)

    setDownloadStarted(false)

    setFileDownloading(false)
  }

  const layoutChange = (e) => {
    // wtf moment
    const toPath = '../'

    if (!e) {
      navigate(toPath)
    }
  }

  return (
    <>
      <Modal
        title={[<div key={1}>{popupTitle}</div>]}
        open={isModalOpen}
        onOk={handleExportOk}
        onCancel={handleExportCancel}
        destroyOnClose={true}
        width={600}
        afterOpenChange={layoutChange}
        footer={<></>}
        maskClosable={downloadStarted}
      >
        <p className="">{popupBody}</p>
        <p className="mt-2 fw-bold">Please stay on this page during the export.</p>

        <Divider></Divider>
        <div className="col-12 d-flex justify-content-center">
          {downloadStarted ? (
            <div className=" d-flex align-items-center justify-content-center flex-column ">
              <div className="d-flex justify-content-center">
                <CheckCircleOutlined style={{ color: '#1aa234', fontSize: '3rem' }} />
              </div>
            </div>
          ) : (
            <div>
              {file ? (
                <div className="" style={{ paddingBottom: 8 }}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={() => triggerDownload(file, type, studyName, setDownloadStarted)}
                  >
                    Click to save file
                  </Button>
                </div>
              ) : (
                <div className="">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

const triggerDownload = (result, type, studyName, setDownloadStarted) => {
  setDownloadStarted(true)

  const url = window.URL.createObjectURL(new Blob([result]))
  const link = document.createElement('a')
  link.href = url

  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = today.getMonth() + 1
  let dd = today.getDate()

  const formattedToday = yyyy + '-' + mm + '-' + dd

  const fileType = type === DOWNLOAD_TYPES.ASSIGNMENTS ? 'assignments report' : 'audits report'

  const fileName = studyName + '_' + fileType + '_' + formattedToday + '.csv'
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()

  link.parentNode?.removeChild(link)
}
