/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/05/2021.
 */
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authStore } from '../../store/auth/authStore'
import './login-form.scss'
import { LOGIN_FORGOT_PASSWORD, LOGIN_MFA_CODE, LOGIN_NEW_PASSWORD } from './PAGES_LOGIN'
import { isValidEmail } from '../../util/isValidEmail'
import { Button } from 'antd'

const LoginForm = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const login = authStore((state) => state.login)

  const [error, setError] = useState('')
  const [processingLogin, setProcessingLogin] = useState(false)

  const handleFormSubmit = (event) => {
    event.preventDefault()

    // Check for input. Set error text if email and/or password is missing
    if (!email) {
      setError('Please enter your email address')
      return
    }

    if (!isValidEmail(email)) {
      setError('Please provide a valid email address')
      return
    }

    if (!password) {
      setError('Please enter your password')
      return
    }

    setError('')

    // While processing login we prevent multiple clicks
    setProcessingLogin(true)

    login(email, password)
      .then((res: any) => {
        console.log(' LoginForm > res = ', res)
        console.log(' LoginForm > res.challengeName = ', res.challengeName)

        if (!res.challengeName) {
          navigate('/', { replace: true })
        }

        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate(LOGIN_NEW_PASSWORD.to, { replace: true })
          return
        }

        if (res.challengeName === 'SMS_MFA') {
          navigate(LOGIN_MFA_CODE.to, { replace: true })
          return
        }
      })
      .catch((error) => {
        console.log(' LoginForm > error = ', error)
        // If both email and password are present then the credentials have been sent. We will then use the error text from server response
        if (email && password) setError(error.message)
      })
      .finally(() => {
        // Login request has been processed. Enabling login button again
        setProcessingLogin(false)
      })
  }

  return (
    <>
      <h2 className="font-weight-bold text-center mt-2">Sign in</h2>
      <p className="font-weight-bold text-center mb-3">To continue to the Portal</p>
      <form className="login-page w-100">
        <div className="w-100 ">
          <label htmlFor="username">E-mail</label>
          <input
            id="username"
            type="text"
            placeholder="Username"
            autoComplete="username"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div className="w-100">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
          <div className="">
            <Button
              type="primary"
              onClick={(event) => handleFormSubmit(event)}
              loading={processingLogin}
              style={{
                background: 'black',
                cursor: 'pointer',
                color: 'white',
                border: '1px solid black',
                borderRadius: 4,
                height: 48,
                width: '100%',
              }}
            >
              Login
            </Button>
            <p className="mt-2 text-center text-danger">{error}</p>
          </div>
        </div>
        <Link to={LOGIN_FORGOT_PASSWORD.to} className="d-flex justify-content-center mt-1">
          <p className="p-small">Forgot password</p>
        </Link>
      </form>
    </>
  )
}
export default LoginForm
