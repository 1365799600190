/**
 * Created by @author Søren Tramm
 */
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormLabel } from '../../components/form/FormLabel'
import { useQuestionnaires } from '../../hooks/useQuestionnaires'
import { Button, Select } from 'antd'
import { useProjectById } from '../../hooks/useProjectById'
import { LanguageISOCode, QuestionType } from '../../Types'
import { BODY_PARTS, LANGUAGE_LABELS } from '../../constants'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
}

export const ProjectQuestionnaireDrawer = ({ closeDrawer, onClose }: Props) => {
  const { projectId, questionnaireId } = useParams() as { projectId: string; questionnaireId: string }
  const { data } = useQuestionnaires(projectId, questionnaireId)

  const { data: projectData } = useProjectById(projectId)
  const supportedLanguages = projectData.languages
  const defaultLanguage = supportedLanguages[0]

  const [currentLanguage, setCurrentLanguage] = useState<string>(defaultLanguage.isoCode)

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const onLanguageHandler = (value) => {
    setCurrentLanguage(value)
  }

  const formatTime = (time: { h?: number; m?: number } = {}): string => {
    const { h, m } = time
    if (h != null && m != null) {
      const hours = h < 10 ? `0${h}` : h
      const minutes = m < 10 ? `0${m}` : m
      return `${hours}:${minutes}`
    }
    return '-'
  }

  return (
    <>
      <div className="">
        <div className="col-12 mb-3">
          <p className="mb-2 p-mini opacity-50">ID: {data._id}</p>
        </div>
        <div className="col-12 mb-3">
          <LanguageDropdown
            supportedLanguages={supportedLanguages}
            defaultLanguage={defaultLanguage}
            onChange={onLanguageHandler}
          />
        </div>
        <div className="col-12 mb-3">
          <FormLabel label="Phase"></FormLabel>
          <h6 className="fw-bold">{data.type ? data.type : '-'}</h6>
        </div>
        <div className="col-12 mb-3">
          <FormLabel label="Internal Title"></FormLabel>
          <h6 className="me-2">{data.internalTitle}</h6>
        </div>
        <div className="col-12 mb-3">
          <FormLabel label="Title"></FormLabel>
          <h6 className="me-2">{currentLanguage ? data?.name[currentLanguage] : '-'}</h6>
        </div>
        <div className="col-12 mb-3">
          <FormLabel label="Onboarding"></FormLabel>
          <h6 className="fw-bold">{data ? (data.onboarding === true ? 'Yes' : 'No') : '-'}</h6>
        </div>

        {!data.onboarding && (
          <>
            <div className="col-12 mb-3">
              <FormLabel label="Recurrence type"></FormLabel>
              <h6 className="fw-bold">{!!data.recurrence && data.recurrence.type ? data.recurrence.type : '-'}</h6>
            </div>
            <div className="col-12 mb-3">
              <FormLabel label="Window"></FormLabel>
              <h6 className="fw-bold">
                {`${formatTime(data.recurrence.startAt)} - ${formatTime(data.recurrence.endAt)}`}
              </h6>
            </div>
            <div className="col-12 mb-3">
              <FormLabel label="Start offset in days"></FormLabel>
              <h6 className="fw-bold">
                {!!data.recurrence && data.recurrence.offset !== undefined ? data.recurrence.offset : '-'}
              </h6>
            </div>
          </>
        )}

        {data.notifications?.reminders && (
          <>
            <div className="col-12 mb-3">
              <FormLabel label="Reminder on activation"></FormLabel>
              <h6 className="fw-bold">{data.notifications.reminders.start[currentLanguage] || '-'}</h6>
            </div>
            <div className="col-12 mb-3">
              <FormLabel label="End reminder"></FormLabel>
              <h6 className="fw-bold">{data.notifications.reminders.end[currentLanguage] || '-'}</h6>
            </div>
            <div className="col-12 mb-3">
              <FormLabel label="End reminder time"></FormLabel>
              <h6 className="fw-bold">{formatTime(data.notifications.reminders.endTime)}</h6>
            </div>
          </>
        )}

        <div className="col-12">
          <FormLabel label="Questions"></FormLabel>
        </div>

        <div className="col-12 px-0 mt-2 mb-4 px-3">
          {data.questions &&
            data.questions.map((question: any, i: number) => (
              <QuestionItem item={question} language={currentLanguage!} key={i} />
            ))}
        </div>

        <div className="col-12 d-flex justify-content-end mt-3">
          <Button type="primary" onClick={navigateBack}>
            Close
          </Button>
        </div>
      </div>
    </>
  )
}

const QuestionItem = ({ item, language, to = './' }) => {
  const isNprsQuestion = useMemo(() => {
    return (item?.type as QuestionType) === 'nprs11pt2l' || item?.type === 'nprs11pt5l' || item?.type === 'nprs5'
  }, [item])

  console.log('QuestionItem > item > ', item)
  return (
    <div
      className="row pb-2 bg-white mt-2"
      style={{
        border: '1px solid',
        borderColor: '#CFD3D7 ',
        boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)',
      }}
    >
      <div className="col-12 pt-3">
        <div className="row">
          <div className="col-12 pb-2 px-2 d-flex align-content-end">
            <p className="p-mini opacity-50">{item._id}</p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Title</p>
            <p className="me-2 fw-bold text-truncate">
              {typeof item.title === 'object' ? item.title[language] : item.title}
            </p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Body</p>
            <p className="mb-1 p-small">{item.body[language]}</p>
          </div>

          {!isNprsQuestion && item?.options?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Options</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {`- ` + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isNprsQuestion && item?.options?.classification?.length && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Option labels</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.classification.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {`- ` + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {item?.correlations?.bodypart?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Body parts</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item?.correlations?.bodypart?.map((part: any, i: number) => (
                  <li className="" key={`bodypart-${i}`}>
                    {'- ' + BODY_PARTS[part]}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-8">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="mb-0 p-mini alpha-80 text-start">Type</p>
                <p className="mb-0 fw-bold p-small text-primary text-capitalize">
                  {item.type ? item.type.toLowerCase() : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const LanguageDropdown = ({
  supportedLanguages,
  defaultLanguage,
  onChange,
}: {
  supportedLanguages: any[]
  defaultLanguage: any
  onChange?: (languageISOCode: LanguageISOCode) => void
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageISOCode>()

  const handleLanguageSelect = useCallback(
    (languageCode: LanguageISOCode) => {
      if (supportedLanguages && languageCode) {
        setSelectedLanguage(languageCode)
        onChange && onChange(languageCode)
      }
    },
    [supportedLanguages, onChange]
  )

  return (
    <>
      <FormLabel label={'Select Language'} />
      <Select
        defaultValue={defaultLanguage.isoCode}
        value={selectedLanguage}
        style={{ width: '100%' }}
        onChange={(val: LanguageISOCode) => handleLanguageSelect(val)}
        options={supportedLanguages?.map((language: any) => ({
          value: language.isoCode,
          label: LANGUAGE_LABELS[language.isoCode] ? LANGUAGE_LABELS[language.isoCode] : language.isoCode,
        }))}
      />
    </>
  )
}
