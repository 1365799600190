/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Button } from 'antd'
import { Suspense, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { PageHeader } from '../../components/display/PageHeader'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useSubjects } from '../../hooks/useSubjects'
import { SubjectCreatePage } from '../subjectCreate/SubjectCreatePage'
import { SubjectOnboardPage } from '../subjectCreate/SubjectOnboardPage'
import { SubjectExportModal } from './SubjectExportModal'
import { SubjectList } from './SubjectList'
import { SubjectView } from './SubjectView'
import { useProjectById } from '../../hooks/useProjectById'
import { PROJECT_STATES } from '../../Types'

//
//
// THIS PAGE IS ONLY FOR CLINICIANS
//
//---------------------------------------------------------------------------------------

export const SubjectClnicianPage = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string; subjectId: string }

  const [isSubjectExportOpen, setIsSubjectExportOpen] = useState(false)

  const showSubjectExport = () => {
    setIsSubjectExportOpen(true)
  }

  const { data } = useSubjects(projectId, siteId)

  const { data: projectData } = useProjectById(projectId)

  return (
    <>
      <Routes>
        <Route
          path={'/*'}
          element={
            <>
              <SubjectList
                subjectList={data.subjects}
                header={
                  <PageHeader
                    title="Subject Overview"
                    body="Manage study participants efficiently within the Subject Overview. Create new subjects, initiate onboarding, and export subject data for analysis. Utilize tabs to filter by status for easy access and review their progress. Explore individual profiles for a comprehensive summary."
                  >
                    <div className="d-flex align-items-center mt-4">
                      <Link to="export">
                        <Button
                          type="default"
                          onClick={showSubjectExport}
                          className="me-3"
                          disabled={projectData.status === 'ARCHIVED'}
                        >
                          <p>Export subject data</p>
                        </Button>
                      </Link>
                      <Link to="./create">
                        <Button type="primary" disabled={projectData.status !== PROJECT_STATES.ACTIVE}>
                          <p>Create New Subject</p>
                        </Button>
                      </Link>
                    </div>
                  </PageHeader>
                }
              ></SubjectList>
              <Routes>
                <Route
                  path="/export"
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <SubjectExportModal
                        isModalOpen={isSubjectExportOpen}
                        setIsSubjectExportOpen={setIsSubjectExportOpen}
                      ></SubjectExportModal>
                    </Suspense>
                  }
                />
              </Routes>
            </>
          }
        />

        <Route
          path={'/view/:subjectId/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
              <SubjectView></SubjectView>
            </Suspense>
          }
        />

        <Route
          path={'onboard/:subjectId'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectOnboardPage></SubjectOnboardPage>
            </Suspense>
          }
        />
        <Route
          path="/create"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectCreatePage></SubjectCreatePage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
