/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/05/2023.
 */
import { AutoComplete, Button, Empty, Tabs, TabsProps } from 'antd'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SubjectType } from '../../Types'
import { ReactComponent as MagIcon } from '../../assets/magnify.svg'
import { ReactComponent as UserIcon } from '../../assets/user.svg'
import { StatusTag } from '../../components/StatusTag'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { ListItemContent } from '../../components/display/ListItemContent'
import { PageHeader } from '../../components/display/PageHeader'
import { useProtocol } from '../../hooks/useProtocol'

type Props = { subjectList: SubjectType[]; header?: React.ReactNode }

export const SubjectList = ({ subjectList, header }: Props) => {
  //
  // Yes we can pass it from the parent component
  // We do this to avoid component drilling
  //
  const { projectId } = useParams() as { projectId: string }
  const navigate = useNavigate()
  const { data: protocol } = useProtocol(projectId)

  const { animated, spring } = useFadeIn()

  const [options, setOptions] = useState<any[]>([])
  // Set the default active tab - default one is for all subjects
  const [activeTab, setActiveTab] = useState('/')

  // Conditional render since the subject list wont have any JSX changes
  // therefore it's better to have everything in 1 component
  const filteredSubjectList = subjectList.filter((subject) => {
    switch (activeTab) {
      case '/':
        return true
      case 'terminated':
        return subject.status === 'TERMINATED'
      case 'completed':
        return subject.status === 'COMPLETED'
      default:
        // Filter by phase title, excluding 'TERMINATED'
        return subject.currentPhase?.title === activeTab && subject.status !== 'TERMINATED'
    }
  })

  const onTabChange = (value) => {
    setActiveTab(value)
  }

  const searching = (searchTxt) => {
    const data = [
      {
        label: <p className="fw-bold ">Subjects</p>,
        options: subjectList
          .filter((subject) => {
            const displayId = subject?.displayId ? subject?.displayId.toLowerCase() : ''
            const searchTxtLower = searchTxt.toLowerCase()

            const matchDisplayId = displayId.indexOf(searchTxtLower) > -1

            const siteId = subject?.siteId?.siteNumber ? subject.siteId.siteNumber.toString() : ''
            const matchSiteId = siteId?.indexOf(searchTxtLower) > -1 || false

            return matchSiteId || matchDisplayId
          })
          .map((subject) => {
            return {
              value: subject._id,
              label: (
                <Link
                  to={'view/' + subject._id}
                  className="d-flex py-2 justify-content-between text-black text-decoration-none  align-items-center "
                >
                  <div className="w-25">
                    <p className="opacity-75 p-mini">Subject</p>
                    <p className="w-25">{subject.displayId}</p>
                  </div>

                  <div className="w-25">
                    <p className="opacity-75 p-mini">Site</p>
                    <p className="">
                      {subject?.siteId?.siteNumber.toString()} - <span>{subject?.siteId?.title}</span>
                    </p>
                  </div>

                  <Button type="default" size="small">
                    Click to view
                  </Button>
                </Link>
              ),
            }
          }),
      },
    ]

    setOptions(data)
  }

  const searchSelect = (value) => {
    console.log('SubjectList > value = ', value)
    navigate('view/' + value)
  }

  // I would prefer we keep the whitespaces for now, not sure if we want to change the URL for every tab
  // The reason I want to keep them is to use the phase.title to refilter the list based on phase title as well
  //const phaseTabItems = protocol.phases.map((phase) => ({ title: phase.title, to: phase.title.replace(/ /g, '') }))

  const phaseTabItems =
    protocol && protocol.phases ? protocol.phases.map((phase) => ({ title: phase.title, to: phase.title })) : ''

  const items: TabsProps['items'] = [
    { title: 'All Subjects', to: '/' },
    { title: 'Terminated', to: 'terminated' },
    { title: 'Completed', to: 'completed' },
    ...phaseTabItems,
  ].map((item, index) => {
    return {
      key: item.to,
      label: item.title,
    }
  })

  const onNavigate = (value) => {
    onTabChange(value)

    // Do we want navigation? I'm not sure - Stoyan
    //navigate(value)
  }

  //const now = dayjs()

  return (
    <>
      <animated.div style={spring} className="d-flex flex-column flex-fill">
        <div
          className="container-fluid bg-white"
          style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="container bg-white px-lg-6">
            {header ? header : <PageHeader title="Subjects"></PageHeader>}

            <div className="row">
              <div className="col-12 ">
                <Tabs
                  destroyInactiveTabPane={false}
                  defaultActiveKey={'0'}
                  indicatorSize={(size) => {
                    return size + 14
                  }}
                  tabBarStyle={{ margin: 0, padding: 0 }}
                  size="middle"
                  items={items}
                  onChange={onNavigate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4 px-lg-6">
          <div className="row" style={{}}>
            <div className="col-12 mb-2">
              <div className="border rounded-5 w-100 py-1 px-3 mb-2 bg-white d-flex align-items-center">
                <MagIcon className="" style={{ width: 20, height: 20 }}></MagIcon>
                <AutoComplete
                  options={options}
                  notFoundContent={'No subjects found'}
                  className="w-100 py-2"
                  placeholder="Search screening id, site number, etc"
                  //onSearch={(text) => setOptions(getPanelValue(text))}
                  onSearch={searching}
                  onSelect={searchSelect}
                  //bordered={false}
                  variant="borderless"
                />
              </div>
            </div>
          </div>
          <div className="row mb-6">
            {filteredSubjectList.length === 0 ? (
              <Empty description="There are no subjects enrolled in the study/site" />
            ) : null}

            {filteredSubjectList.map((subject, index) => {
              const phaseTitle = subject.currentPhase?.title
              const siteNum = subject.siteId?.siteNumber

              //const phaseDuration = subject.currentPhase.duration
              //const startDate = dayjs(subject.createdAt)
              //const endDate = dayjs(startDate).set('date', startDate.get('date') + phaseDuration)
              //const daysLeft = endDate.diff(now, 'day')

              return (
                <div key={index} className="col-12 mb-1">
                  <div
                    className="bg-white d-flex align-items-center py-2 px-3  "
                    style={{
                      borderBottom: '1px solid',
                      borderColor: '#e8e8e8',
                    }}
                  >
                    <div className="me-3 ms-2 ">
                      <UserIcon style={{ width: 30, height: 30 }}></UserIcon>
                    </div>

                    <ListItemContent
                      label="Subject ID:"
                      title={subject.displayId}
                      style={{ width: '25%' }}
                    ></ListItemContent>

                    <ListItemContent label="Phase" title={phaseTitle} style={{ width: '25%' }} />
                    {/*                    <ListItemContent label="Days left" title={daysLeft.toString()} style={{ width: '15%' }} />*/}
                    <ListItemContent label="Site" title={siteNum} style={{ width: '20%' }} />

                    <div className="d-flex" style={{ width: '10%' }}>
                      <StatusTag status={subject.status} />
                    </div>

                    <div className="d-flex justify-content-end" style={{ width: '20%' }}>
                      <Link to={'view/' + subject._id} className="px-3">
                        <Button type="primary" className="">
                          View
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </animated.div>
    </>
  )
}
