/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import formatDistance from 'date-fns/formatDistance'
import { useSubject } from '../../hooks/useSubject'
import { useNavigate, useParams } from 'react-router-dom'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { DEVICE_STATE, PROJECT_STATES, SUBJECT_STATUS_ENUM } from '../../Types'
import { authStore } from '../../store/auth/authStore'
import { useProjectById } from '../../hooks/useProjectById'
import TextArea from 'antd/es/input/TextArea'
import { DeviceDetailsTrialStaff } from './DeviceDetailsTrialStaff'
import { COLOR } from '../../constants'
import { DeviceWipeModal } from './DeviceWipeModal'

/*## Managing device and subject state

The system handles two different states. The device state and the user state. They work independent of each other,
A complete walkthroug of the DeviceDetails component can be found here
https://github.com/StudiesAndMe/epro-portal/issues/257

- [x] Subject is **active**
- [x] Device is **active**

- [x] Subject is **active**
- [x] Device is **DISABLED**

- [x] Subject is **TERMINATED**
- [x] Device is **active**

This is a weird case, but can happen
- [x] Subject is **TERMINATED**
- [x] Device is **disabled**

- [x] Subject is **TERMINATED**
- [x] Device is **WIPED**

- [x] Subject is active
- [x] Device is wiped and in **wipe state**

// added 03/09-2024
- [x] Subject is created
- [x] Device is wiped and in **wipe state**

- [x] Subject is active
- [x] Device is ENROLL_NEW_DEVICE - meaning the **Enroll new device** button has been clicked but no new device has been setup

- [x] Subject is Terminated
- [x] Device is ENROLL_NEW_DEVICE - meaning the **Enroll new device** button has been clicked but no new device has been setup

*/

export const DeviceDetails = () => {
  const navigate = useNavigate()
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }

  const userAtt = authStore((state) => state.user?.attributes)

  // TODO need to be improved
  const isClinician = userAtt?.['custom:userType'] === 'CLINICIAN'

  const { data: subjectData, mutate: mutateSubjectData } = useSubject(projectId, subjectId)
  const { data: projectData } = useProjectById(projectId)

  const isSubjectActive =
    subjectData.status === SUBJECT_STATUS_ENUM.ACTIVE || subjectData.status === SUBJECT_STATUS_ENUM.CREATED

  console.log('DeviceDetails > isSubjectActive === ', isSubjectActive)

  const [showWipeModal, setShowWipeModal] = useState<boolean>(false)

  const deviceStateUpdatedAt = subjectData?.device?.updatedAt
  const deviceState = subjectData?.device?.state || DEVICE_STATE.ACTIVE

  //
  // Only show enroll new device if:
  //  - device is wiped
  //  - device is ENROLL_NEW_DEVICE
  //  - subject is active in the study
  //
  const allowEnrollNewDevice =
    (deviceState === DEVICE_STATE.WIPED && isSubjectActive) ||
    (deviceState === DEVICE_STATE.ENROLL_NEW_DEVICE && isSubjectActive)

  const timeAgo = deviceStateUpdatedAt
    ? formatDistance(new Date(deviceStateUpdatedAt), new Date(), {
        addSuffix: true,
      })
    : ''

  const disableDevice = () => {
    setDisableDeviceStatue(DEVICE_STATE.DISABLE_DEVICE)
  }

  const enableDevice = (justification) => {
    // we send enable so the event can go into the audits
    setDisableDeviceStatue(DEVICE_STATE.ENABLE_DEVICE, justification)
  }

  const setDisableDeviceStatue = (type: string, justificationReason: string = '') => {
    const obj = {
      projectId: projectId,
      subjectId: subjectId,
      type: type,
      deviceId: subjectData?.deviceId,
      reason: justificationReason ? justificationReason : '',
    }

    const path = ENDPOINTS.SUBJECTS.disableDevice(projectId)
    post(path, obj, 'PUT')
      .then((res) => {
        console.log('DeviceDetails > res = ', res)
        mutateSubjectData()
      })
      .catch((err) => {
        console.log('DeviceDetails > err = ', err)
        alert(err.message)
        //throw new Error(err)
      })
  }

  const enrollNew = () => {
    enrollNewDevice()
  }

  const showModalDeviceWipe = () => {
    setShowWipeModal(true)
  }

  const enrollNewDevice = () => {
    const obj = {
      projectId: projectId,
      subjectId: subjectId,
      type: DEVICE_STATE.ENROLL_NEW_DEVICE,
    }

    const path = ENDPOINTS.SUBJECTS.enrollNewDevice(projectId)
    post(path, obj, 'PUT')
      .then((res) => {
        console.log('DeviceDetails > res = ', res)
        mutateSubjectData()

        if (res.data) {
          console.log('DeviceDetails > res.data._id = ', res.data._id)
          navigate(`../onboard/${res.data._id}`)
        }
      })
      .catch((err) => {
        console.log('DeviceDetails > err = ', err)
        alert(err.message)
        //throw new Error(err)
      })
  }

  const prettyDeviceState = (state) => {
    if (state === DEVICE_STATE.DISABLE_DEVICE) {
      return 'Disabled'
    }

    if (state === DEVICE_STATE.WIPE_DEVICE) {
      return 'Confirm wipe'
    }

    if (state === DEVICE_STATE.WIPED) {
      return 'Wiped'
    }

    return state
  }

  return (
    <>
      <DeviceWipeModal
        show={showWipeModal}
        setShow={setShowWipeModal}
        deviceId={subjectData.deviceId}
        mutateSubjectData={mutateSubjectData}
      ></DeviceWipeModal>
      <div className="container-fluid rounded-2">
        <div className="row">
          <div className="row " style={{}}>
            <div className="col-8 bg-white p-4 block-shadow " style={{ border: '1px solid #a3a3a3a' }}>
              <div className="mb-4">
                <h3 className="mb-1">Device Management</h3>
                <p className="mb-2">To manage devices, choose from the following:</p>
                {timeAgo ? <p className="p-small text-primary">Updated {timeAgo}</p> : null}
                <hr />
              </div>
              {!isClinician ? (
                <DeviceDetailsTrialStaff></DeviceDetailsTrialStaff>
              ) : (
                <>
                  <SubjectTerminated isSubjectActive={isSubjectActive} deviceState={deviceState}></SubjectTerminated>

                  <DeviceDisabled
                    deviceState={deviceState}
                    disableDevice={disableDevice}
                    enableDevice={enableDevice}
                    projectStatus={projectData.status}
                    isSubjectActive={isSubjectActive}
                  ></DeviceDisabled>

                  <ReplaceDevice
                    deviceState={deviceState}
                    projectStatus={projectData.status}
                    showModalDeviceWipe={showModalDeviceWipe}
                  ></ReplaceDevice>

                  {allowEnrollNewDevice ? (
                    <>
                      <h5 className="fw-bold mb-3">Device replacement </h5>
                      <p>
                        If you need to enroll a new device for the subject, please click the button below and follow the
                        onboarding steps.
                      </p>
                      <div className="col-12 mt-4">
                        <Button onClick={enrollNew} className="px-4" size="large" type="primary">
                          Enroll new device
                        </Button>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>

            {/* --------     RIGHT SIDE OF THE ROW   --------      */}

            <div className="col-4 h-100">
              <div className="row  h-100" style={{}}>
                <div className="col-12  h-100  ">
                  <div className="bg-white rounded-2  block-shadow h-100 p-4">
                    <p className="p-small opacity-75">Device state:</p>
                    <p className="mb-3 fw-bold">{prettyDeviceState(deviceState)}</p>
                    <p className="p-small opacity-75">Device Id:</p>
                    <p className="fw-bold">{subjectData?.deviceId}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SubjectTerminated = ({ isSubjectActive, deviceState }) => {
  return isSubjectActive === false ? (
    <div className="col-12 mt-4">
      <h5 className="text-danger">Please note </h5>
      <p className="text-danger">
        The subject is no longer active in the study and the device has automaticlly been
        <span className="fw-bold"> Disabled</span>. No action is required from you.
      </p>
    </div>
  ) : null
}

const ReplaceDevice = ({ projectStatus, deviceState, showModalDeviceWipe }) => {
  if (
    deviceState === DEVICE_STATE.WIPE_DEVICE ||
    deviceState === DEVICE_STATE.WIPED ||
    deviceState === DEVICE_STATE.ENROLL_NEW_DEVICE
  ) {
    return null
  }

  return (
    <div className="col-12 mt-4 px-4 py-4 rounded-3" style={{ background: COLOR.LIGHT_GRAY }}>
      <h5 className="mb-3 mt-1">Replace Device</h5>
      <p>
        Click on <b>Replace Device</b> to permanently wipe and replace a device. A wiped device can then be assigned to
        a new subject
      </p>

      <Button
        onClick={showModalDeviceWipe}
        className="px-5 mt-3"
        size="large"
        danger
        disabled={projectStatus !== 'ACTIVE'}
      >
        Replace device
      </Button>
    </div>
  )
}

const DeviceDisabled = ({ enableDevice, projectStatus, disableDevice, deviceState, isSubjectActive }) => {
  const [justification, setJustification] = useState<string>('')

  const handleReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setJustification(e.target.value)
  }

  useEffect(() => {
    setJustification('')
  }, [deviceState])

  const enableClick = () => {
    enableDevice(justification)
  }

  if (
    deviceState === DEVICE_STATE.WIPE_DEVICE ||
    deviceState === DEVICE_STATE.WIPED ||
    deviceState === DEVICE_STATE.ENROLL_NEW_DEVICE ||
    isSubjectActive === false
  ) {
    return null
  }

  return (
    <>
      {deviceState === DEVICE_STATE.ENABLE_DEVICE || deviceState === DEVICE_STATE.ACTIVE ? (
        <>
          <div className="col-12 mt-4 px-4 py-4 rounded-3" style={{ background: COLOR.LIGHT_GRAY }}>
            <h6 className="mb-3 mt-1">Disable Device</h6>
            <p>
              Click on <b>Disable Device</b> to temporarily lock a device from use. A disabled device can be re-enabled
              for the same subject
            </p>

            <Button
              onClick={disableDevice}
              type="primary"
              className="px-5 mt-3"
              size="large"
              danger
              disabled={projectStatus !== PROJECT_STATES.ACTIVE}
            >
              Disable device
            </Button>
          </div>
        </>
      ) : (
        <div className="col-12 mt-4 rounded-3 border-danger" style={{ background: COLOR.LIGHT_GRAY }}>
          <div className="py-3 px-3">
            <h5 className="fw-bold mb-3 mt-1 ">The device has been manual disabled</h5>
            <hr />
            <p className="fw-bold opacity-75 mb-2 ">Made a mistake</p>
            <p className="mb-3 opacity-75">
              Please provide a reason for the desired change and click the button below to re-enable the device for the
              subject
            </p>

            <TextArea
              disabled={projectStatus !== PROJECT_STATES.ACTIVE}
              className="mb-2"
              allowClear
              placeholder="Justification for enabling a device"
              rows={3}
              onChange={handleReason}
            />

            <div className="d-flex justify-content-end">
              <Button onClick={enableClick} type="primary" className="mt-1 px-4" disabled={justification.length < 5}>
                Enable device
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
