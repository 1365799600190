/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Button } from 'antd'
import { Suspense, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { PageHeader } from '../../components/display/PageHeader'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useProjectById } from '../../hooks/useProjectById'
import { useSubjects } from '../../hooks/useSubjects'
import { SubjectCreatePage } from '../subjectCreate/SubjectCreatePage'
import { SubjectExportModal } from './SubjectExportModal'
import { SubjectList } from './SubjectList'
import { SubjectView } from './SubjectView'

export const SubjectTrialstaffPage = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const [isSubjectExportOpen, setIsSubjectExportOpen] = useState(false)

  const showSubjectExport = () => {
    setIsSubjectExportOpen(true)
  }

  const { data } = useSubjects(projectId, siteId)

  const { data: projectData } = useProjectById(projectId)

  return (
    <>
      <Routes>
        <Route
          path={'/view/:subjectId/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectView></SubjectView>{' '}
            </Suspense>
          }
        />
        {/*SUBJECT LIST*/}
        <Route
          path={'/*'}
          element={
            <>
              <SubjectList
                header={
                  <PageHeader
                    title="Subject Overview"
                    body="View study participants in the Subject Overview. 
                  Review all participants and their progress, and explore individual profiles for details. Utilise tabs to filter by statuses and export subject data for analysis."
                  >
                    <Link to="export">
                      <Button
                        type="default"
                        onClick={showSubjectExport}
                        className="me-3"
                        disabled={projectData.status === 'ARCHIVED'}
                      >
                        <p>Export subject data</p>
                      </Button>
                    </Link>
                  </PageHeader>
                }
                subjectList={data.subjects}
              ></SubjectList>
              <Routes>
                <Route
                  path="/export"
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <SubjectExportModal
                        isModalOpen={isSubjectExportOpen}
                        setIsSubjectExportOpen={setIsSubjectExportOpen}
                      ></SubjectExportModal>
                    </Suspense>
                  }
                />
              </Routes>
            </>
          }
        />

        {/* -----
        SUBJECT CREATE
        ------ */}
        <Route
          path="/create"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectCreatePage></SubjectCreatePage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
