/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25/04/2023.
 */
import { useParams } from 'react-router-dom'
import { useMeDms } from '../../hooks/useMeDms'
import { Empty } from 'antd'
import { TrialStaffListRender } from './TrialStaffListRender'

//TODO: When you get props pass them, uncommented to avoid empty object pattern
//type Props = {}

export const DmView = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useMeDms(projectId)

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        {data.length > 0 ? (
          <TrialStaffListRender data={data} linkPath="view-staff/" />
        ) : (
          <div className="col-12 mt-5">
            <Empty description="There are no active trial staff associated with the study"></Empty>
          </div>
        )}
      </div>
    </div>
  )
}
