import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { mutate as mutateGlobal } from 'swr'
import { COUNTRY_TYPE, CRUD_VIEW_TYPE } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { getFormType } from '../../components/form/formFactory'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useById } from '../../hooks/useById'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { isValidEmail } from '../../util/isValidEmail'
import { isValidPhone } from '../../util/isValidPhone'
import { populateConfig } from '../../util/populateConfig'
import { getTrialStaffPiiConfig } from './trialStaffPiiConfig'
import { openNotification } from '../../components/notificationToast'

type Props = {
  onClose?: () => void
}

export const TrialStaffPii = ({ onClose }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const p = userId ? ENDPOINTS.TRIAL_STAFF.get(projectId) : ''
  const { data, isError, mutate } = useById(p, userId)

  const { data: configData } = useByEndpoint(ENDPOINTS.PROJECTS.CONFIG)
  const supportedCountries: COUNTRY_TYPE[] = configData.countries

  const configObj = data ? populateConfig(getTrialStaffPiiConfig(), data, 'UPDATE') : getTrialStaffPiiConfig()

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()
  const [reasonDescription, setReasonDescription] = useState<string>('')

  const handleReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReasonDescription(e.target.value)
  }

  const onSubmit = (formData, e) => {
    e.preventDefault()

    if (!isValidEmail(formData.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    const phone = formData.phone.countryCode + formData.phone.tel

    if (formData.phone.tel.length < 5 || !isValidPhone(phone)) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    }

    if (reasonDescription.length === 0) {
      setErrorState({ message: 'Please provide a reason for the change' })
      return
    }

    if (reasonDescription.length < 6) {
      setErrorState({ message: 'The reason must be at least 6 characters long' })
      return
    }

    if (isValidEmail(formData.email) && isValidPhone(phone)) {
      setErrorState(undefined)
    }

    const updatedData = { ...formData, reasonDescription, projectId: projectId, _id: userId }

    return post(ENDPOINTS.TRIAL_STAFF.ACTION.updatePii(projectId), updatedData, 'PUT')
      .then(() => {
        openNotification(
          'Personal information updated',
          'The personal information has been updated successfully',
          'happy'
        )

        mutate()
        mutateGlobal(ENDPOINTS.CRA.byProjectId(projectId))
        mutateGlobal(ENDPOINTS.CTM.byProjectId(projectId))
        mutateGlobal(ENDPOINTS.DM.byProjectId(projectId))
        if (onClose) {
          onClose()
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (e) => {}

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12">
          {configObj.map((item, index) => {
            const comp = getFormType(item, index, formHook, CRUD_VIEW_TYPE.CREATE, supportedCountries)
            return comp
          })}
          <div className="mt-2 mb-2">
            <FormLabel label="Please provide a reason for the desired update of personal information"></FormLabel>
            <TextArea
              allowClear
              placeholder="Justification for updating personal information"
              rows={2}
              onChange={handleReason}
            />
          </div>
        </div>

        <div className="col-12 mb-4">
          <div
            className="h-100 px-4 py-2 rounded-1  bg-gradient"
            style={{
              boxShadow: '0px 3px 4px -3px rgba(101, 99, 95, .4)',
              border: '1px solid rgb(164, 163, 155, .6)',
              background: '#fffaf1',
            }}
          >
            <div className="row">
              <div className="col-1 d-flex justify-content-center">
                <ExclamationCircleOutlined style={{ scale: '150%', color: 'rgba(164, 163, 155, 1)' }} />
              </div>
              <div className="col-11">
                <p className="p-small" style={{ color: '#5d5b58' }}>
                  To edit the Trial Staff Information, make changes, provide a written reason, and press "Save changes"
                  to save the updates.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mb-3">
          {errorState ? (
            <div className="mb-2">
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            </div>
          ) : (
            <></>
          )}
          {isError ? (
            <>
              <Alert showIcon message={isError.type} description={isError.message} type="error" />
            </>
          ) : null}
        </div>

        <div className="col-12 d-flex justify-content-end">
          <Button className="me-2" onClick={navigateBack}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
            Save changes
          </Button>
        </div>
      </form>
    </>
  )
}
